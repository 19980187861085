import { Docs } from '@splytech-io/router';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { createParamSetter, normalizeRouterPath } from '../lib/helpers';
import { EndpointSpecLayout } from './EndpointSpecLayout';
import { printSpecsSection } from './EndpointSpecsPage';
import { WhenDefined } from './FX';
import { JSONInspect } from './JSONInspect';
import { MethodBadge } from './MethodBadge';
import { PageSections } from './PageSections';
import { RequestExamples } from './RequestExamples';
import { ResponseExamples } from './ResponseExamples';
import { ResponseSpecs } from './ResponseSpecs';
import { TryMe } from './TryMe';


export function EndpointWebhookSpecsPage({
  endpoint,
  baseUrl,
}: {
  endpoint: Docs.WebhookEndpoint;
  baseUrl: string;
}) {
  const [requestPayload, setRequestPayload] = useState<object>({});
  const [showEndpointSpecs, setShowEndpointSpecs] = useState(false);

  const setRequestPayloadProperty = createParamSetter(setRequestPayload);

  useEffect(() => {
    setRequestPayload({});
  }, [endpoint]);

  return (
    <>
      <EndpointSpecLayout
        left={<>
          <h2>{endpoint.documentation.title}</h2>
          <div className="mb-4 mt-2">
            <MethodBadge method={endpoint.method}/>
            <code className="text-muted ms-2">
              https://your.company.com/some/path
              <strong>{normalizeRouterPath(endpoint.path)}</strong>
            </code>
          </div>

          <p className="lead">{endpoint.documentation.description}</p>

          <WhenDefined value={endpoint.documentation.content} then={(items) =>
            <PageSections items={items.filter((item) => item.placement !== 'bottom')}/>
          }/>

          <hr/>

          <WhenDefined value={endpoint.validation.params?.keys} then={(keys) =>
            printSpecsSection('Path Params', keys, requestPayload, setRequestPayloadProperty)
          }/>

          <WhenDefined value={endpoint.validation.query?.keys} then={(keys) =>
            printSpecsSection('Query Params', keys, requestPayload, setRequestPayloadProperty)
          }/>

          <WhenDefined value={endpoint.validation.requestBody?.keys} then={(keys) =>
            printSpecsSection('Body Params', keys, requestPayload, setRequestPayloadProperty)
          }/>

          <WhenDefined value={endpoint.validation.responseBody?.keys} then={(keys) =>
            <ResponseSpecs keys={keys}/>
          }/>

          <WhenDefined value={endpoint.validation.requestBody?.examples} then={(examples) =>
            RequestExamples({ examples })
          }/>

          <WhenDefined value={endpoint.validation.responseBody?.examples} then={(examples) =>
            ResponseExamples({ examples })
          }/>

          <WhenDefined value={endpoint.documentation.content} then={(items) =>
            <PageSections items={items.filter((item) => item.placement === 'bottom')}/>
          }/>

          <Button variant={'link'} onClick={() => setShowEndpointSpecs(true)}>Debug</Button>
        </>}
        right={<>
          <h5 className="mb-4">Try me</h5>
          <TryMe endpoint={endpoint}
                 baseUrl={baseUrl}
                 requestPayload={requestPayload}
                 requestParams={{}}
                 requestQuery={{}}
          />
        </>}
      />


      <Modal
        show={showEndpointSpecs}
        size="lg"
        centered
        onHide={() => setShowEndpointSpecs(false)}
      >
        <Modal.Body>
          <JSONInspect json={endpoint}/>
          {/*  <pre className="overflow-scroll">*/}
          {/*    <code>{dump(endpoint)}</code>*/}
          {/*    /!*{dump(endpoint)}*!/*/}
          {/*  </pre>*/}
        </Modal.Body>
      </Modal>

      {/*<pre className="mt-5 overflow-scroll">{YAML.dump(endpoint)}</pre>*/}
    </>
  );
}
