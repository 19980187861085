export function SplytLogo(props: {
  color?: 'white' | 'splyt-blue';
  width?: number;
  height?: number;
}) {
  const fill = props.color === 'splyt-blue' ? '#46BEDB': 'white';

  return (
    <svg width={props.width ?? 80} height={props.height ?? 25} viewBox="0 0 80 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.0264 14.3822C64.7848 10.6988 66.5618 6.57103 66.9875 2.72096H70.4304C69.8936 7.71865 67.0801 13.4197 64.7848 17.8991C63.4706 20.6201 62.0454 23.6002 58.4359 23.6002V20.435C58.5285 20.435 58.5285 20.435 58.5285 20.435C60.1203 20.435 60.6386 19.2874 61.3605 17.9732C59.0652 13.4937 56.2517 7.79269 55.6224 2.88755H59.0652C59.491 6.57103 61.1754 10.6988 63.0264 14.3822ZM51.8834 12.5497C51.8834 14.0676 52.5868 15.1226 54.4378 15.0486H55.9371V18.3248H53.6418C50.199 18.3248 48.6256 15.826 48.4405 13.068V0H51.8834V12.5497ZM78.3711 15.0301H80V18.3248H77.6307C74.0953 18.3248 72.4479 15.826 72.2813 13.068L72.1888 0H75.7242V2.94308H79.9074V6.23785H75.7242V12.5497C75.7242 14.049 76.4646 15.0301 78.3711 15.0301ZM39.9815 3.18371C43.8871 3.18371 47.1633 6.7006 47.1633 10.6432V10.9024C47.1633 14.7709 43.9796 18.2693 39.9815 18.2693H38.0379V24.0629H34.5766V3.18371H39.9815ZM31.3003 10.8838C32.3739 11.6798 32.8366 12.8274 32.8366 14.1601C32.9292 17.7881 30.4859 18.4914 27.1541 18.4914H23.1004V15.3077H27.1541C28.9496 15.3077 29.4123 14.956 29.4123 14.2527C29.4123 13.2716 27.5243 12.5683 26.7099 12.2166C25.5437 11.6798 24.8218 11.2355 24.1925 10.7173C23.193 9.82879 22.6562 8.77372 22.6562 7.53355C22.5636 3.9056 25.1735 3.20222 28.3387 3.20222H31.4669V6.29338H28.3202C26.5248 6.29338 26.062 6.64507 26.062 7.34845C26.062 8.32948 27.95 9.03286 28.7645 9.38455C29.8565 9.90282 30.6525 10.3471 31.3003 10.8838ZM5.81212 3.18371C10.273 7.2559 10.273 11.6798 10.1805 16.8996C10.1805 17.3438 10.1805 17.8806 10.1805 18.3248H6.8857C6.8857 17.7881 6.8857 17.2698 6.8857 16.8255C6.97825 11.9574 6.97825 8.68117 3.59093 5.59001L0 2.38778L2.23971 0L5.81212 3.18371ZM10.3656 6.53401C9.75474 5.44192 8.86627 4.18325 7.8112 3.18371H18.6395V6.53401H10.3656ZM43.7205 10.9024V10.6432C43.7205 8.53309 42.0361 6.60805 40 6.60805H38.0565V14.9375H39.9815C42.0361 14.9375 43.7205 12.9199 43.7205 10.9024Z" fill={fill}/>
    </svg>
  );
}
