export function whenDefined<T, U>(
  value: T,
  then: (value: Exclude<T, undefined | null>) => U,
): U | undefined;
export function whenDefined<T, U, V>(
  value: T,
  then: (value: Exclude<T, undefined | null>) => U,
  otherwise: () => V,
): U | V | undefined;
export function whenDefined<T, U, V>(
  value: T,
  then: (value: Exclude<T, undefined | null>) => U,
  otherwise?: () => V,
): U | V | undefined {
  return (value !== undefined && value !== null) ? then(value as any) : otherwise?.();
}


export function use<T, U>(value: T, fn: (value: T) => U): U {
  return fn(value);
}

export function whenTruthy<T, U, V>(
  value: T,
  then: (value: Exclude<T, undefined | null | false>) => U,
  otherwise?: (value: T) => V
): U | V | undefined {
  return value ? then(value as any) : otherwise?.(value);
}

export function switchOn<T, U>(value: T, cases: Record<string, ((value: T) => U) | string>): U | undefined {
  const caseValue: ((value: T) => U) | string = cases[value as any];

  if (typeof caseValue === 'string') {
    return switchOn(caseValue as any, cases);
  }

  return caseValue?.(value);
}

export function t<T>(t: T): T {
  return t;
}
