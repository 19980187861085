import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styled from 'styled-components';
import { classNames } from '../lib/utils';
import { SwitchOn } from './FX';

export function Chevron(props: {
  expanded: boolean;
  setExpanded?: (expanded: boolean) => void;
  color?: 'black' | 'gray';
}) {
  return (
    <Component className={classNames({
      gray: props.color === 'gray',
    })}>
      <SwitchOn value={props.expanded} cases={{
        'true': () => <FaChevronUp onClick={() => props.setExpanded?.(false)}/>,
        'false': () => <FaChevronDown onClick={() => props.setExpanded?.(true)}/>,
      }}/>
    </Component>
  );
}

const Component = styled.div`
  &.gray {
    color: #aaa;
  }
`;
