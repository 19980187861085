import { HTMLAttributes } from 'react';
import Badge from 'react-bootstrap/Badge';
import { Color } from 'react-bootstrap/types';
import styled from 'styled-components';
import { switchOn } from '../lib/fx';

export function MethodBadge(props: {
  method: string;
} & HTMLAttributes<HTMLDivElement>) {
  const {
    method,
    ...attrs
  } = props;

  const type: Color = switchOn(method, {
    'POST': () => 'primary',
    'GET': () => 'success',
    'DELETE': () => 'danger',
    'PATCH': () => 'warning',
    'PUT': () => 'warning',
  }) ?? 'secondary';

  return (
    <MyBadge bg={type} {...attrs}>{method}</MyBadge>
  );
}

const MyBadge = styled(Badge)`
  width: 60px;
`;
