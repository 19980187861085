import { Docs } from '@splytech-io/router';
import { dump } from 'js-yaml';
import { MouseEventHandler, ReactElement, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import FormControl from 'react-bootstrap/FormControl';
import FormSelect from 'react-bootstrap/FormSelect';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Popover from 'react-bootstrap/Popover';
import Stack from 'react-bootstrap/Stack';
import { FaChevronDown, FaChevronUp, FaInfoCircle } from 'react-icons/fa';
import { getType } from '../lib/helpers';
import { classNames } from '../lib/utils';
import { MapElements, SwitchOn, Use, WhenDefined, WhenTruthy } from './FX';
import { Markdown } from './Markdown';
import { SectionCard } from './SectionCard';

const printResponseParamSpecChildren = (keys: Record<string, Docs.TElement>): ReactElement[] => {
  return Object.entries(keys).map(([key, value]) =>
    <ResponseParamSpecItem key={key} name={key} value={value}/>,
  );
};

export function ResponseSpecs(props: {
  keys: Record<string, Docs.TElement>
}) {

  return (
    <SectionCard caption="Response Specs" content={() =>
      <ListGroup variant={'flush'}>
        {printResponseParamSpecChildren(props.keys)}
      </ListGroup>
    }/>
  );
}

export function ResponseParamSpecItem({
  name,
  value,
}: {
  name: string;
  value: Docs.TElement;
}) {
  const [expanded, setExpanded] = useState(false);
  const isExpandable = ['array[object]', 'array[string]', 'array[alternatives]'].includes(getType(value)) ||
    (getType(value) === 'object' && !!(value as Docs.TObject).keys);

  const handleOnItemClick: MouseEventHandler = (evt) => {
    evt.stopPropagation();

    if (isExpandable) {
      setExpanded(!expanded);
    }
  };

  return (
    <ListGroupItem key={name} variant={'secondary'}>
      <Stack direction="horizontal" gap={2} onClick={handleOnItemClick} className={classNames({
        clickable: isExpandable,
        clicked: expanded,
      })}>
        <div>
          <strong>
            <code className="field-name">{name}</code>
            {value.flags?.presence === 'optional' ? '?' : ''}
          </strong>

          <WhenDefined value={value.examples} then={(examples) =>
            <div className="d-inline ms-1">
              <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={
                <Popover id="popover-contained">
                  <Popover.Body>
                    <label>Example value{examples.length > 1 ? 's' : ''}:</label>
                    <ul className="m-0 list-unstyled">
                      {examples.map((example, index) =>
                        <li key={index}>
                          <code>{dump(example)}</code>
                        </li>,
                      )}
                    </ul>
                  </Popover.Body>
                </Popover>
              }>
                <span><FaInfoCircle/></span>
              </OverlayTrigger>
            </div>
          }/>

          <small className="text-muted ms-1">{getType(value)}</small>
          <WhenTruthy value={value.flags?.description} then={(description) =>
            <Markdown className="field-description" content={description}/>
          }/>
        </div>

        <WhenTruthy
          value={isExpandable}
          then={() =>
            <div className="ms-auto">
              <SwitchOn value={expanded} cases={{
                'true': () => <FaChevronUp className={'property-field-button'}/>,
                'false': () => <FaChevronDown className={'property-field-button'}/>,
              }}/>
            </div>
          }/>
        <WhenTruthy value={getType(value) === 'string' && value.allow} then={() =>
          <WhenDefined value={value.allow} then={(allow) =>
            <SwitchOn value={allow.length === 1} cases={{
              'false': () =>
                <FormSelect className="my-input ms-auto" defaultValue="">
                  <option disabled value="" hidden>Enum values</option>
                  <MapElements items={allow} content={(item) => <option>{item}</option>}/>
                </FormSelect>,
              'true': () =>
                <FormControl className="ms-auto my-input" value={allow[0]} readOnly/>,
            }}/>
          }/>
        }/>
      </Stack>

      <WhenTruthy value={expanded} then={() =>
        <SwitchOn value={getType(value)} cases={{
          'object': () =>
            <div className="mt-2">
              <Use value={value as Docs.TObject} fn={(value) =>
                <ListGroup>
                  {printResponseParamSpecChildren(value.keys ?? {})}
                </ListGroup>
              }/>
            </div>,
          'array[object]': () =>
            <div className="mt-2">
              <Use value={value as Docs.TArray<Docs.TObject>} fn={(value) =>
                <ListGroup>
                  {printResponseParamSpecChildren(value.items[0].keys ?? {})}
                </ListGroup>
              }/>
            </div>,
          'array[alternatives]': () =>
            <div className="mt-2">
              <Use value={((value as Docs.TArray).items[0] as Docs.TAlternatives).matches} fn={(matches) =>
                matches.map((match, index) =>
                  <>
                    {index > 0 ? <p className={'mb-2 text-center'}>- OR -</p> : <></>}
                    <ListGroup className={'mb-2'}>
                      {printResponseParamSpecChildren((match.schema as Docs.TObject).keys ?? {})}
                    </ListGroup>
                  </>,
                )
              }/>
            </div>,
          'array[string]': () =>
            <div className="mt-2">
              <Use value={(value as Docs.TArray<Docs.TString & Docs.TCommon>).items[0]} fn={(item) =>
                <WhenDefined value={item.allow} then={(allow) =>
                  <ListGroup>
                    <ListGroupItem variant="dark">
                      <strong>Allowed values</strong>
                    </ListGroupItem>
                    {allow.map((item) => <ListGroupItem>{item}</ListGroupItem>)}
                  </ListGroup>
                }/>
              }/>
            </div>,
        }}/>
      }/>
    </ListGroupItem>
  );
}
