import React, { HTMLAttributes, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaCopy } from 'react-icons/fa';
import styled from 'styled-components';

export function CopyButton(props: {
  text: string;
  noBorder?: boolean;
} & HTMLAttributes<HTMLDivElement>) {
  const {
    text,
    noBorder,
    ...attrs
  } = props;
  const button = useRef(null);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const handleCopy = () => {
    navigator.permissions.query({ name: 'clipboard-write' as any }).then(result => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(text).then(() => {
          setShowCopiedMessage(true);
          setTimeout(() => {
            setShowCopiedMessage(false);
          }, 1000);
        });
      }
    });
  };

  return (
    <Component {...attrs}>
      <Button ref={button} variant="outline-secondary" size="sm" onClick={handleCopy} className={noBorder ? 'border-0' : ''}>
        <FaCopy/>
      </Button>

      <Overlay target={button.current} show={showCopiedMessage} placement="bottom">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Copied!
          </Tooltip>
        )}
      </Overlay>
    </Component>
  );
}


const Component = styled.div`
`;
