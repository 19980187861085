import { dump } from 'js-yaml';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FaGlasses } from 'react-icons/fa';
import styled from 'styled-components';
import { MyStorage } from '../lib/my-storage';
import { classNames } from '../lib/utils';
import { CodePreview } from './CodePreview';
import { CopyButton } from './CopyButton';
import { SwitchOn } from './FX';

const displayTypeStorage = MyStorage.create('JSONInspect.displayType');

export function JSONInspect({
  json,
  title,
}: {
  json: object;
  title?: string;
}) {
  const [displayType, setDisplayType] = useState(() => displayTypeStorage.read(0));

  useEffect(() => {
    displayTypeStorage.write(displayType);
  }, [displayType]);

  const handleToggleDisplayType = () => setDisplayType((displayType + 1) % 2);

  return (
    <Container className={classNames({}, 'position-relative json-inspect')}>
      <div className={classNames({
        overText: !title,
      }, 'd-flex align-items-center justify-content-end header')}>
        {title && <code className={'me-auto mb-0 text-white ms-3'}>{title}</code>}

        <Button variant="outline-secondary" size="sm" onClick={handleToggleDisplayType} className={'border-0'}>
          <FaGlasses/>
        </Button>

        <CopyButton text={JSON.stringify(json)} noBorder={true}/>
      </div>

      <div className="position-relative">
        <SwitchOn value={displayType} cases={{
          '0': () => <CodePreview
            text={JSON.stringify(json, null, 2)}
            lang={'json'}
          />,
          '1': () => <CodePreview
            text={dump(json).trim()}
            lang={'yaml'}
          />,
        }}/>
      </div>

    </Container>
  );
}

const Container = styled.section`
  background-color: #282c34;

  pre {
    padding: 0;
  }

  h6 {
    font-family: Courier;
    padding-left: 20px;
  }

  .header {
    border-bottom: 1px solid #444;

    &.overText {
      border: 0;
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 10;
    }
  }
`;
