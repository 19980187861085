import { ReactElement, useState } from 'react';
import { switchOn, use, whenDefined, whenTruthy } from '../lib/fx';

export function WhenTruthy<T, U, V>({
  value,
  then,
  otherwise,
}: {
  value: T;
  then: (value: Exclude<T, undefined | null | false>) => U;
  otherwise?: (value: T) => V
}) {
  return (<>{whenTruthy(value, then, otherwise)}</>);
}

export function WhenDefined<T, U>(props: {
  value: T;
  then: (value: Exclude<T, undefined | null>) => U;
}): ReactElement;
export function WhenDefined<T, U, V>(props: {
  value: T;
  then: (value: Exclude<T, undefined | null>) => U;
  otherwise: () => V;
}): ReactElement;
export function WhenDefined<T, U, V>(props: {
  value: T;
  then: (value: Exclude<T, undefined | null>) => U;
  otherwise?: () => V;
}): ReactElement {
  return (<>{props.otherwise
    ? whenDefined(props.value, props.then, props.otherwise)
    : whenDefined(props.value, props.then)}</>);
}


export function Use<T, U>(props: {
  value: T;
  fn: (value: T) => U;
}) {
  return (<>{use(props.value, props.fn)}</>);
}


export function SwitchOn<T, U>(props: {
  value: T;
  cases: Record<string, ((value: T) => U) | string>;
}) {
  return (switchOn(props.value, props.cases) ?? <></>);
}

/**
 * if value is unset then it returns previously set value
 *
 * @param {{value: T, fn: (value: T) => React.ReactElement}} props
 * @returns {JSX.Element}
 * @constructor
 */
export function FreezeValue<T>(props: {
  value: T;
  fn: (value: T) => ReactElement;
}) {
  const [value] = useState<T>(props.value);
  // console.log('FreezeValue', props.value, value);

  return (<>{props.fn(value)}</>);
}


export function MapElements<T>(props: {
  items: Array<T> | undefined;
  content: (item: T, index: number) => ReactElement;
}) {
  return (
    <>{props.items?.map((item, index) => props.content(item, index))}</>
  );
}
