import { Docs } from '@splytech-io/router';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Modal from 'react-bootstrap/Modal';
import { createParamSetter, normalizeRouterPath } from '../lib/helpers';
import { MyStorage } from '../lib/my-storage';
import { EndpointSpecLayout } from './EndpointSpecLayout';
import { Use, WhenDefined, WhenTruthy } from './FX';
import { JSONInspect } from './JSONInspect';
import { Markdown } from './Markdown';
import { MethodBadge } from './MethodBadge';
import { PageSections } from './PageSections';
import { PropertyField } from './PropertyField';
import { RequestExamples } from './RequestExamples';
import { ResponseExamples } from './ResponseExamples';
import { ResponseSpecs } from './ResponseSpecs';
import { SectionCard } from './SectionCard';
import { TryMe } from './TryMe';

const Storage = {
  params: MyStorage.create('EndpointSpecsPage.params'),
  query: MyStorage.create('EndpointSpecsPage.query'),
  payload: MyStorage.create('EndpointSpecsPage.payload'),
};

export function EndpointSpecsPage({
  endpoint,
  baseUrl
}: {
  endpoint: Docs.Endpoint;
  baseUrl: string;
}) {
  const markdownVariables = {
    API_URL: baseUrl,
  };
  const endpointId = `${endpoint.method}.${endpoint.path}`;
  const [requestParams, setRequestParams] = useState<Record<string, string>>(() =>
    Storage.params.suffix(endpointId).read({})
  );
  const [requestQuery, setRequestQuery] = useState<Record<string, string | number>>(() =>
    Storage.query.suffix(endpointId).read({})
  );
  const [requestPayload, setRequestPayload] = useState<object>(() =>
    Storage.payload.suffix(endpointId).read({})
  );
  const [showEndpointSpecs, setShowEndpointSpecs] = useState(false);

  const setRequestPayloadProperty = createParamSetter(setRequestPayload);

  useEffect(() => {
    Storage.params.suffix(endpointId).write(requestParams);
    Storage.query.suffix(endpointId).write(requestQuery);
    Storage.payload.suffix(endpointId).write(requestPayload);
  }, [requestParams, requestQuery, requestPayload, endpointId]);

  return (
    <>
      <EndpointSpecLayout
        left={<>
          <h2>{endpoint.documentation.title}</h2>
          <div className="mb-4 mt-2">
            <MethodBadge method={endpoint.method}/>
            <code className="text-muted ms-2">{normalizeRouterPath(endpoint.path)}</code>
          </div>

          <div className="lead mb-3">
            <Markdown content={endpoint.documentation.description} variables={markdownVariables}/>
          </div>

          <WhenDefined value={endpoint.documentation.content} then={(items) =>
            <PageSections items={items.filter((item) => item.placement !== 'bottom')}/>
          }/>

          <hr/>

          <WhenDefined value={endpoint.validation.params?.keys} then={(keys) =>
            printSpecsSection('Path Params', keys, requestParams, (key, value) => {
              setRequestParams((previous) => ({
                ...previous,
                [key]: value || undefined,
              }));
            })
          }/>

          <WhenDefined value={endpoint.validation.query?.keys} then={(keys) =>
            printSpecsSection('Query Params', keys, requestQuery, (key, value) => {
              setRequestQuery((previous) => ({
                ...previous,
                [key]: value || undefined,
              }));
            })
          }/>

          <WhenDefined value={endpoint.validation.requestBody?.keys} then={(keys) =>
            printSpecsSection('Body Params', keys, requestPayload, (key, value) => {
              setRequestPayloadProperty(key, value);
            })
          }/>

          <WhenDefined value={endpoint.validation.requestBody?.examples} then={(examples) =>
            RequestExamples({ examples })
          }/>

          <WhenDefined value={endpoint.validation.responseBody?.keys} then={(keys) =>
            <ResponseSpecs keys={keys}/>
          }/>

          <WhenDefined value={endpoint.validation.responseBody?.examples} then={(examples) =>
            ResponseExamples({ examples })
          }/>


          <WhenDefined value={endpoint.documentation.content} then={(items) =>
            <PageSections items={items.filter((item) => item.placement === 'bottom')}/>
          }/>

          <Button variant={'link'} onClick={() => setShowEndpointSpecs(true)}>Debug</Button>
        </>}
        right={<>
          {/*<h5 className="mb-4">Try me</h5>*/}
          <TryMe endpoint={endpoint}
                 baseUrl={baseUrl}
                 requestPayload={requestPayload}
                 requestParams={requestParams}
                 requestQuery={requestQuery}
          />
        </>}
      />


      <Modal
        show={showEndpointSpecs}
        size="lg"
        centered
        onHide={() => setShowEndpointSpecs(false)}
      >
        <Modal.Body>
          <JSONInspect json={endpoint}/>
          {/*  <pre className="overflow-scroll">*/}
          {/*    <code>{dump(endpoint)}</code>*/}
          {/*    /!*{dump(endpoint)}*!/*/}
          {/*  </pre>*/}
        </Modal.Body>
      </Modal>

      {/*<pre className="mt-5 overflow-scroll">{YAML.dump(endpoint)}</pre>*/}
    </>
  );
}


export const printSpecsSection = (
  caption: string,
  specs: Record<string, Docs.TElement>,
  userValue: Record<string, any>,
  store: (key: string, value: any) => void,
) => {
  return (
    <SectionCard caption={caption} content={() =>
      <ListGroup variant={'flush'}>
        <Use value={Object.entries(specs)} fn={(entries) =>
          <WhenTruthy
            value={entries.length !== 0}
            then={() =>
              entries.map(([key, spec]) =>
                <ListGroupItem key={key} variant={'secondary'}>
                  <PropertyField name={key}
                                 spec={spec}
                                 userValue={userValue?.[key]}
                                 onSet={(value) => store(key, value)}
                  />
                </ListGroupItem>,
              )
            }
            otherwise={() =>
              <ListGroupItem variant={'secondary'}>Empty</ListGroupItem>
            }
          />
        }/>
      </ListGroup>
    }/>
  );
};
