import { ReactElement } from 'react';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';

export function SectionCard({
  caption,
  content,
}: {
  caption: string;
  content: () => ReactElement
}) {
  return (
    <Container className="mb-4">
      <h6>{caption}</h6>
      <Card>
        {content()}
      </Card>
    </Container>
  );
}

const Container = styled.section`
  .list-group-item-secondary {
    background-color: #F8F9FA;
    
    .property-field-button {
      color: #BBBBBB;
    }
    
    .btn-outline-secondary {
      color: #BBBBBB;
      border-color: #BBBBBB;
      padding: 5px;
      line-height: 10px;
    }
  }
`;
