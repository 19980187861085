import React, { ReactElement } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import styled from 'styled-components';

export function EndpointSpecLayout(props: {
  left: ReactElement;
  right: ReactElement;
}) {
  return (
    <Component className="ps-3 pb-3">
      <Row className="w-100">
        <Col lg={6} xl={6} className={'pb-3'}>
          {props.left}
        </Col>
        <Col lg={6} xl={6} className={'vh-100'}>
          <div className="sticky-top">
            {props.right}
          </div>
        </Col>
      </Row>

    </Component>
  );
}


const Component = styled.div`
  padding-bottom: 10%;

  .sticky-top {
    top: 15px
  }
`;
