import styled from 'styled-components';

export const StyledText = styled.div`
  blockquote {
    padding: 10px 20px;
    margin-left: 20px;
    margin-right: 20px;
    border-left: 2px solid #ddd;
    border-radius: 3px;
    //opacity: 0.7;

    & > p:last-child {
      margin-bottom: 0;
    }
  }

  code {
    background-color: #eee;
    padding: 3px 5px;
    border-radius: 3px;
    color: #000;
    //white-space: nowrap;
    //font-weight: 500;
    white-space: pre-wrap;
  }

  pre code {
    //font-family: Courier;
    font-weight: bold;
  }

  p + h5 {
    margin-top: 30px;
  }

  p + h6 {
    margin-top: 20px;
  }

  .image-container {
    text-align: center;

    img {
      max-height: 600px;
      max-width: 100%;
    }
  }
`;
