import { marked } from 'marked';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { HTMLInject } from './HTMLInject';

export function Markdown(props: {
  content: string | undefined;
  variables?: Record<string, string>;
} & HTMLAttributes<HTMLDivElement>) {
  const {
    content,
    ...attrs
  } = props;
  const html = marked(content ?? '')
    .replace(/\n<\/code>/g, '</code>');

  return (<Container {...attrs}>
    <HTMLInject html={html} variables={props.variables}/>
  </Container>);
}

const Container = styled.div`
  & > p:last-child {
    margin-bottom: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

`;
