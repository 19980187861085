import { Docs } from '@splytech-io/router';
import { Dispatch, SetStateAction } from 'react';
import { Card } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import { FaTimes } from 'react-icons/fa';
import { getType, getUniqueElementId } from '../lib/helpers';
import { SwitchOn } from './FX';
import { PropertyField } from './PropertyField';

type DataType = Array<object>;
type Setter = Dispatch<SetStateAction<DataType>>;

export function PropertyFieldChildrenElements({
  childrenElements,
  setChildrenElements,
  spec,
  userValue,
}: {
  childrenElements: DataType;
  setChildrenElements: Setter;
  spec: Docs.TArray;
  userValue: any;
}) {
  const childSpec = spec.items[0] as Docs.TElement;

  return (<>
    {childrenElements.map((element, childIndex) =>
      <Card key={getUniqueElementId(element)} className="mt-2">
        <ListGroup>
          <SwitchOn value={getType(childSpec)} cases={{
            // 'string': () =>
            //   <Use value={(arrayElement as Docs.TString)} fn={(spec) =>
            //     <ListGroupItem variant={'secondary'}>
            //       <PropertyField name={`[${childIndex}]`}
            //                      spec={spec}
            //                      userValue={userValue?.[childIndex]}
            //                      onSet={(value) => {
            //                        // onSet({
            //                        //   // [key]: value || undefined,
            //                        // })}
            //                      }}/>
            //     </ListGroupItem>
            //   }/>,
            'object': () => <ElementObject
              childrenElements={childrenElements}
              setChildrenElements={setChildrenElements}
              userValue={userValue?.[childIndex]}
              childIndex={childIndex}
              childSpec={childSpec as Docs.TObject}
            />,

            'alternatives': () => <ElementAlternatives
              childrenElements={childrenElements}
              setChildrenElements={setChildrenElements}
              userValue={userValue?.[childIndex]}
              childIndex={childIndex}
              childSpec={childSpec as Docs.TAlternatives<Docs.TObject>}
            />
            ,
          }}/>
        </ListGroup>
      </Card>,
    )}
  </>);
}

function ElementObject({
  childSpec,
  setChildrenElements,
  childrenElements,
  userValue,
  childIndex,
}: {
  childSpec: Docs.TObject,
  setChildrenElements: Setter;
  childrenElements: DataType;
  userValue: any;
  childIndex: number;
}) {
  return (<>
    <Header
      childIndex={childIndex}
      childrenElements={childrenElements}
      setChildrenElements={setChildrenElements}
    />
    {Object.entries(childSpec.keys ?? {}).map(([key, spec]) =>
      <ListGroupItem key={key} variant={'secondary'}>
        <PropertyField
          name={key}
          spec={spec}
          userValue={userValue?.[childIndex]?.[key]}
          onSet={(value) => {
            // console.log(spec.type, userValue, childIndex, key, value);
            setChildrenElements((previous) => {
              const previousValueNormalized = previous ?? [];
              (previousValueNormalized[childIndex] as any)[key] = value || undefined;

              return [...previousValueNormalized];
            });
          }}/>
      </ListGroupItem>,
    )}
  </>);
}

function ElementAlternatives({
  childSpec,
  setChildrenElements,
  userValue,
  childrenElements,
  childIndex,
}: {
  childSpec: Docs.TAlternatives<Docs.TObject>,
  setChildrenElements: Setter;
  childrenElements: DataType;
  userValue: any;
  childIndex: number;
}) {
  return (<>
    <Header
      childIndex={childIndex}
      childrenElements={childrenElements}
      setChildrenElements={setChildrenElements}
    />
    <ListGroupItem variant={'secondary'}>
      {childSpec.matches.map((match, index) =>
        <div key={index}>
          {index > 0 ? <div className={'my-2 text-center text-muted'}>- OR -</div> : <></>}
          <ListGroup>
            {Object.entries(match.schema.keys ?? {}).map(([key, spec]) =>
              <ListGroupItem key={key} variant={'secondary'}>
                <PropertyField name={key} spec={spec} userValue={userValue?.[key]} onSet={(value) => {

                  setChildrenElements((previous) => {
                    const previousValueNormalized = previous ?? [];
                    (previousValueNormalized[childIndex] as any)[key] = value || undefined;

                    return [...previousValueNormalized];
                  });

                }}/>
              </ListGroupItem>,
            )}
          </ListGroup>
        </div>,
      )}
    </ListGroupItem>
  </>);
}


function Header({
  childIndex,
  childrenElements,
  setChildrenElements,
}: {
  childrenElements: Array<object>;
  childIndex: number;
  setChildrenElements: Setter;
}) {
  return (<>
    <ListGroupItem className="py-1 d-flex justify-content-between align-items-center" variant="secondary">
      <small className={'text-muted'}>[{childIndex}]</small>

      <FaTimes size={12} className="clickable" onClick={() => {
        childrenElements.splice(childIndex, 1);
        setChildrenElements([...childrenElements]);
      }}/>
    </ListGroupItem>
  </>);
};
