import { HTMLAttributes, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { classNames } from '../lib/utils';
import { WhenDefined } from './FX';

export function Callout({
  children,
  variant,
  caption,
  ...attrs
}: PropsWithChildren<{
  caption?: string;
  variant?: 'info' | 'warning' | 'danger'
} & HTMLAttributes<HTMLDivElement>>) {
  return (<Container {...attrs} className={classNames({
    'callout': true,
    'callout-warning': variant === 'warning',
    'callout-info': variant === 'info',
    'callout-danger': variant === 'danger',
  }, attrs.className)}>
    <WhenDefined value={caption} then={(caption) =>
      <strong className='d-block'>{caption}</strong>
    }/>
    {children}
  </Container>)
}

const Container = styled.div`
  &.callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #e9ecef;
    border-left-width: 0.25rem;
    border-radius: 0.25rem;
  }

  &.callout-warning {
    border-left-color: #f0ad4e;
  }

  &.callout-info {
    border-left-color: #5bc0de;
  }

  &.callout-danger {
    border-left-color: #d9534f;
  }
`;
