import { Docs } from '@splytech-io/router';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import { ApiReferencePageMenu } from '../Components/ApiReferencePageMenu';
import { EndpointSpecsPage } from '../Components/EndpointSpecsPage';
import { SwitchOn, WhenDefined } from '../Components/FX';
import { TopBar } from '../Components/TopBar';
import { EndpointWebhookSpecsPage } from '../Components/EndpointWebhookSpecsPage';
import { use } from '../lib/fx';
import { getUniqueElementId } from '../lib/helpers';
import { classNames } from '../lib/utils';
import { FreeformPage } from './FreeformPage';
import { splytWebsiteMenuItems } from './splyt-website-menu-items';

export function ApiReferencePage({
  documentation,
  baseUrl,
}: {
  documentation: Docs.Documentation | Docs.Router;
  baseUrl: string;
}) {
  const markdownVariables = {
    API_URL: baseUrl,
  };
  const [showMenu, setShowMenu] = useState(false);
  const [selectedPage, setSelectedPage] = useState<Docs.Page | Docs.Endpoint | Docs.WebhookEndpoint>();

  useEffect(() => {
    const setOverflow = (hidden: boolean) =>
      window.document.documentElement.style.overflow = hidden ? 'hidden' : '';

    if (showMenu) {
      window.document
        .querySelector('.menu')
        ?.scrollTo(0, 0);
    }

    setOverflow(showMenu);

    return () => {
      setOverflow(false);
    };
  }, [showMenu]);

  return (

    <Component>
      <TopBar variant={'wide'} onBurgerClick={() => setShowMenu((previous) => !previous)} menuItems={splytWebsiteMenuItems}/>

      <div
        className={classNames({
          show: showMenu,
        }, 'position-absolute start-0 top-0 w-100 h-100 backdrop')}
        onClick={() => setShowMenu(false)}
      />

      <div className="d-flex align-content-stretch pt-4">
        <div className={classNames({
          'px-3 menu': true,
          'show': showMenu,
        })}>
          <div className="d-flex d-xl-none justify-content-between align-items-center mb-3 pt-2">
            <h4 className={'mb-0'}>Menu</h4>

            <Button variant={'link'} onClick={() => setShowMenu((previous) => !previous)}>
              <FaTimes/>
            </Button>
          </div>

          <ApiReferencePageMenu documentation={documentation} onItemSelect={(item) => {
            setSelectedPage(item);
            setShowMenu(false);
          }} isShowing={showMenu}/>
        </div>
        <div className="content">

          <WhenDefined value={selectedPage} then={(selectedPage) =>
            <SwitchOn value={selectedPage.type} cases={{
              'endpoint': () => use(selectedPage as Docs.Endpoint, (endpoint) =>
                <EndpointSpecsPage baseUrl={baseUrl} key={getUniqueElementId(endpoint)} endpoint={endpoint}/>,
              ),
              'webhook-endpoint': () => use(selectedPage as Docs.WebhookEndpoint, (endpoint) =>
                <EndpointWebhookSpecsPage baseUrl={baseUrl} key={getUniqueElementId(endpoint)} endpoint={endpoint}/>,
              ),
              'page': () => use(selectedPage as Docs.Page, (page) =>
                <FreeformPage page={page} variables={markdownVariables}/>,
              ),
            }}/>

          }/>

        </div>
      </div>
    </Component>
  );
}

const Component = styled.section`
  padding-top: 10px;
  
  .content {
    width: 100vw;
  }

  //@media (min-width: 991px) {
  @media (min-width: 1200px) {
    .content {
      width: calc(100vw - 300px);
    }
  }

  .menu {
    padding-bottom: 10% !important;

    //h5 {
    //  text-transform: uppercase;
    //  font-size: 12px;
    //  font-weight: 700;
    //}
  }


  .backdrop {
    background: rgba(0, 0, 0, 0.3);
    z-index: 10000;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    
    &.show {
      pointer-events: auto;
      opacity: 1;
    }
  }

  .menu {
    width: 300px;
    padding-bottom: 10%;
  }

  //@media (max-width: 991px) {
  @media (max-width: 1199px) {
    .menu {
      position: absolute;
      background-color: white;
      top: 0;
      left: -100%;
      height: 100%;
      z-index: 10000;
      transition: left 0.2s ease-in-out;
      overflow: scroll;

      &.show {
        left: 0;
      }
    }
  }
  
`;
