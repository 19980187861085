export namespace MyStorage {
  export function create<T>(key: string, storage = window.sessionStorage) {
    return {
      read,
      write,
      suffix(suffixKey: string) {
        return create(`${key}.${suffixKey}`, storage);
      },
    };

    function write(value: T) {
      storage.setItem(key, JSON.stringify(value));
    };

    function read<T>(): T | undefined;
    function read<T>(defaultValue: T): T;
    function read<T>(defaultValue?: T) {
      const value = storage.getItem(key);

      if (value) {
        return JSON.parse(value);
      }

      return defaultValue;
    }
  }
}
