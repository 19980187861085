import React, { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaBars } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { switchOn, whenDefined } from '../lib/fx';
import { classNames } from '../lib/utils';
import { SplytLogo } from './SplytLogo';

export function TopBar(props: {
  onBurgerClick?: () => void;
  variant: 'centered' | 'wide';
  menuItems?: Array<{
    title: string;
    url: string;
    children?: Array<{
      title: string;
      url: string;
    }>;
  }>;
}) {
  const navigate = useNavigate();

  return (
    <Header>
      <div className={`${props.variant === 'wide' ? 'container-fluid' : 'container'}`}>

        {props.onBurgerClick &&
          <Nav className="me-3 d-blokc d-xl-none">
            <Nav.Link onClick={props.onBurgerClick}>
              <FaBars/>
            </Nav.Link>
          </Nav>
        }

        <Navbar.Brand as="h1" className={'ps-2 clickable'} onClick={() => navigate('/')}>
          <SplytLogo color={'splyt-blue'} height={33} width={110}/>
          <span className={'subtitle'}>docs</span>
        </Navbar.Brand>

        {false && whenDefined(props.menuItems, (menuItems) =>
          <Navbar.Collapse id="menu">
            <Nav className={classNames({
              [props.variant === 'centered' ? 'mx-auto' : 'me-auto']: true,
              'ms-4': props.variant === 'wide',
            }, 'gap-3 d-none d-lg-flex')}>
              {menuItems.map((item) =>
                switchOn((item.children !== undefined), {
                  true: () =>
                    <NavDropdown title="Solutions" id="basic-nav-dropdown">
                      {item.children?.map((item) =>
                        <NavDropdown.Item href={item.url}>{item.title}</NavDropdown.Item>,
                      )}
                    </NavDropdown>,
                  false: () =>
                    <Nav.Link href={item.url}>{item.title}</Nav.Link>,
                }),
              )}

              {/*<NavDropdown title="Solutions" id="basic-nav-dropdown">*/}
              {/*<NavDropdown.Item href="https://splyt.com/solutions/superapps/">For superapps</NavDropdown.Item>*/}
              {/*<NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
              {/*<NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
              {/*<NavDropdown.Divider/>*/}
              {/*<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
              {/*</NavDropdown>*/}
              {/*<Nav.Link href="https://splyt.com">Products</Nav.Link>*/}
              {/*<NavDropdown title="About" id="basic-nav-dropdown">*/}
              {/*  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
              {/*  <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
              {/*  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
              {/*  <NavDropdown.Divider/>*/}
              {/*  <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
              {/*</NavDropdown>*/}
              {/*<Nav.Link href="https://splyt.com">News</Nav.Link>*/}

            </Nav>
          </Navbar.Collapse>,
        )}

        <Navbar id='menu'>
          <Nav className={'ms-auto'}>
            <Nav.Link href="https://splyt.com">splyt.com</Nav.Link>
          </Nav>
        </Navbar>

        {/*<div style={{ width: '178px' }}>&nbsp;</div>*/}

        {/*<Nav className="me-auto">*/}
        {/*  <Nav.Link onClick={() => navigate('/')}>*/}
        {/*    Home*/}
        {/*  </Nav.Link>*/}
        {/*</Nav>*/}
      </div>
    </Header>
  );
}

const Header = styled(Navbar)`
  //background-color: #46BEDB !important;
  //background-color: white !important;
  //color: #fff;
  //padding: 10px 20px;

  #menu {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;

    a {
      color: #000 !important;
    }
    
    a:hover {
      color: #46BEDB !important;
      text-decoration: underline;
    }

    .nav-item.show > a {
      color: #46BEDB !important;
    }

    //.dropdown-toggle::after {
    //  display: none !important;
    //}

    .dropdown-menu {
      padding: 0;
      background: none;
      border: none;
      font-size: 12px;

      a {
        padding-left: 8px;
        color: #46BEDB !important;

        &:hover {
          background: none;
          text-decoration: underline;
        }
      }
    }
  }


  .subtitle {
    font-weight: bold;
    color: #457B9D;
    font-size: 24px;
    margin-left: 5px;
  }

  h1 {
    font-weight: 100;
    margin-bottom: 0
  }
`;
