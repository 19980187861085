import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Config } from '../App';
import { TopBar } from '../Components/TopBar';
import WorldSVG from '../images/world.svg';
import { splytWebsiteMenuItems } from './splyt-website-menu-items';

export function Home(props: {
  config: Config;
}) {
  return (
    <Component>
      <TopBar variant="centered" menuItems={splytWebsiteMenuItems}/>

      <Container className={'mt-3'}>
        <Row className={'leading-header'}>
          <Col className={'text-center'}>
            <h1>{props.config.home.header}</h1>
            <p className={'lead'}>
              {props.config.home.intro}
            </p>
          </Col>
        </Row>

        <Row>
          {props.config.apis.map((api, index) =>
            <Col key={index} lg={6} className="mb-3">
              <Card>
                <div className="d-flex w-100 justify-content-between align-items-center py-3 px-4">
                  <div>
                    <h3>
                      <Link to={`/reference/${api.id}`} className="stretched-link">
                        {api.title}
                      </Link>
                    </h3>
                    {api.description && <p className={'mb-0'}>{api.description}</p>}
                  </div>

                  <div className="bg-image" style={{
                    backgroundImage: `url(${api.image})`,
                  }}/>
                </div>

                {/*<Row className={'h-100'}>*/}
                {/*  <Col md={4} className={'mh-100'}>*/}
                {/*    <div className="h-100 w-100 bg-image" style={{*/}
                {/*      backgroundImage: `url(${api.image})`,*/}
                {/*    }}/>*/}
                {/*  </Col>*/}
                {/*  <Col md={8} className={''}>*/}
                {/*    <Card.Body className="d-flex align-items-start flex-column h-100">*/}
                {/*      <Card.Title as="h5">{api.title}</Card.Title>*/}

                {/*      <Link to={`/reference/${api.id}`} className="btn btn-link mt-auto stretched-link">*/}
                {/*        /!*Documentation*!/*/}
                {/*      </Link>*/}
                {/*    </Card.Body>*/}
                {/*  </Col>*/}
                {/*</Row>*/}


              </Card>
            </Col>,
          )}
        </Row>
      </Container>
    </Component>
  );
}

const Component = styled.section`
  padding-top: 30px;

  h3 a {
    text-decoration: none;
    font-size: 24px;
    color: #000;
  }

  .bg-image {
    height: 100px;
    width: 160px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .mh-100 {
    min-height: 100px;
  }

  .card:hover {
    border-color: #46BEDB;
  }

  .leading-header {
    position: relative;
    padding: 100px 0 30px 0;

    &:after {
      content: '';
      background-image: url(${WorldSVG});
      background-position: top center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
    }
  }
`;
