import { foldGutter } from '@codemirror/fold';
import { lineNumbers } from '@codemirror/gutter';
import { defaultHighlightStyle } from '@codemirror/highlight';
import { javascriptLanguage } from '@codemirror/lang-javascript';
import { jsonLanguage } from '@codemirror/lang-json';
import { pythonLanguage } from '@codemirror/lang-python';
import { java, objectiveC } from '@codemirror/legacy-modes/mode/clike';
import { go } from '@codemirror/legacy-modes/mode/go';
import { shell } from '@codemirror/legacy-modes/mode/shell';
import { yaml } from '@codemirror/legacy-modes/mode/yaml';
import { swift } from '@codemirror/legacy-modes/mode/swift';
import { EditorState, StateEffect } from '@codemirror/state';
import { StreamLanguage } from '@codemirror/stream-parser';
import { oneDark } from '@codemirror/theme-one-dark';
import { EditorView } from '@codemirror/view';
import { useEffect, useRef } from 'react';
import { whenDefined } from '../lib/fx';

export function CodePreview(props: {
  text: string;
  lang: 'json' | 'javascript' | 'python' | 'go' | 'shell' | 'yaml' | string | undefined;
  theme?: 'bright';
}) {
  const element = useRef<HTMLDivElement>(null);
  const editorView = useRef<EditorView>();

  const language = (() => {
    switch (props.lang) {
      case 'json':
        return jsonLanguage;
      case 'javascript':
      case 'node':
        return javascriptLanguage;
      case 'python':
        return pythonLanguage;
      case 'go':
        return StreamLanguage.define(go);
      case 'shell':
        return StreamLanguage.define(shell);
      case 'yaml':
        return StreamLanguage.define(yaml);
      case 'java':
        return StreamLanguage.define(java);
      case 'swift':
        return StreamLanguage.define(swift);
      case 'objective_c':
        return StreamLanguage.define(objectiveC);
      default:
        return detectLanguage();
    }
  });

  function detectLanguage() {
    const { text } = props;

    if (text.match(/^(cd|git|source|pod) /)) {
      return StreamLanguage.define(shell);
    }

    if (text.match(/((maven|implementation) |(class\.java))/)) {
      return StreamLanguage.define(java);
    }
  }

  const defaultThemeOption = EditorView.theme({
    '&': {
      maxHeight: '700px',
    },
  });

  const extensions = [
    defaultThemeOption,
    lineNumbers(),
    foldGutter(),
    EditorView.editable.of(false),
    ...(props.theme === 'bright' ? [
      defaultHighlightStyle,
    ] : [
      oneDark,
    ]),
  ];

  whenDefined(language(), (v) => {
    extensions.push(v);
  });

  useEffect(() => {
    if (!element.current) {
      return;
    }

    const editor = editorView.current = new EditorView({
      state: EditorState.create({
        doc: props.text,
        extensions,
      }),
      parent: element.current,
    });

    return () => {
      editor.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element.current]);

  useEffect(() => {
    editorView.current?.dispatch({
      changes: {
        from: 0,
        to: editorView.current.state.doc.length,
        insert: props.text ?? '',
      },
    });
  }, [props.text]);

  useEffect(() => {
    editorView.current?.dispatch({
      effects: StateEffect.reconfigure.of(extensions),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lang]);

  return (<>
    <div ref={element}/>
  </>);
}
