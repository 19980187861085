import { Docs } from '@splytech-io/router';
import { ChangeEventHandler, Dispatch, SetStateAction } from 'react';

export const getType = (value: Docs.TElement): string => {
  if (
    value.matches?.some(
      (item) =>
        item.schema.type === 'object' && Object.keys(item.schema.keys,
        ).includes('_bsontype'))
  ) {
    return 'ObjectID';
  }

  if (value.type === 'array') {
    const itemType = value.items?.[0].type;

    if (!itemType) {
      return 'array';
    }

    return `array[${itemType}]`;
  }

  return value.type;
};


export const statusCodeText = (statusCode: number): string => {
  const map: Record<string, string> = {
    '200': 'Success',
    '201': 'Created',
    '204': 'No Content',
    '400': 'Bad Request',
    '401': 'Unauthorized',
    '403': 'Forbidden',
    '404': 'Not Found',
    '405': 'Method Not Allowed',
    '500': 'Internal Server Error',
    '502': 'Bad Gateway',
    '503': 'Service Unavailable',
    '504': 'Gateway Timeout',
  };

  return map[statusCode.toString()] ?? '';
};

export const targetValue = (fn: (value: string) => void): ChangeEventHandler<any> => {
  return (evt) => {
    fn(evt.target.value);
  };
};

export const normalizeRouterPath = (path: string) => {
  return path
    .replace(/\/:([^\\/]+)/g, '/[$1]')
    .replace(/\\:/g, ':');
};

export function createParamSetter<T>(setter: Dispatch<SetStateAction<T>>) {
  return (key: string, value: any) => {
    if (value === undefined) {

      return setter((prev) => ({
        ...prev,
        [key]: undefined,
      }));
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        return setter((prev) => ({
          ...prev,
          [key]: value,
        }));
      }

      return setter((prev) => ({
        ...prev,
        [key]: {
          ...((prev as any)[key] ?? {}),
          ...value,
        },
      }));
    }

    if (typeof value === 'string') {
      return setter((prev) => ({
        ...prev,
        [key]: value || undefined,
      }));
    }

    setter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
}

export const getUniqueElementId = (() => {
  const map = new WeakMap();

  return (value: any) => {
    try {
      if (!map.has(value)) {
        map.set(value, Math.random().toString(36).slice(2) + Date.now());
      }

      return map.get(value);
    } catch (e) {
      console.error(value, e);
      return '';
    }
  };
})();
