import { Docs } from '@splytech-io/router';
import React, { useEffect, useState } from 'react';
import FormControl from 'react-bootstrap/FormControl';
import Nav from 'react-bootstrap/Nav';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { use } from '../lib/fx';
import { getUniqueElementId, targetValue } from '../lib/helpers';
import { classNames } from '../lib/utils';
import { Chevron } from './Chevron';
import { SwitchOn, Use, WhenDefined, WhenTruthy } from './FX';


const ENABLE_MENU_COLLAPSE = true;

type SelectableItem = Docs.Endpoint | Docs.WebhookEndpoint | Docs.Page;
type SelectableItemTree = Array<Docs.Documentation | Docs.DocumentationItem | Docs.Endpoint | Docs.WebhookEndpoint | Docs.Page>;

export function ApiReferencePageMenu({
  documentation,
  onItemSelect,
  isShowing,
}: {
  documentation: Docs.Root;
  onItemSelect: (item: SelectableItem) => void;
  isShowing: boolean;
}) {
  const [selectedPageTree, setSelectedPageTree] = useState<Array<Docs.Documentation | Docs.DocumentationItem | SelectableItem>>([]);
  const [inputJumpTo, setInputJumpTo] = useState<string>('');
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const pageIds: Map<SelectableItem, string> = makeIdMap(documentation);

  useEffect(() => {
    const defaultPageId: string = Array.from(pageIds.values())[0];
    const page: string = params.page ?? defaultPageId;
    const selectedItemTree = getPageTree(page, documentation, []);

    if (!selectedItemTree) {
      return;
    }

    const pageToShow = selectedItemTree[selectedItemTree.length - 1] as SelectableItem;

    if (pageToShow) {
      onItemSelect(pageToShow);
      setSelectedPageTree(selectedItemTree);
      document.documentElement.style.scrollBehavior = 'auto';
      window.scrollTo(0, 0);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentation, params]);

  const filter = filterItems(inputJumpTo);
  const handleItemSelection = (item: SelectableItem) => {
    const parts = location.pathname.split('/');
    parts[3] = pageIds.get(item)!;

    navigate({
      pathname: parts.join('/'),
    });
  };

  return (
    <div>
      <FormControl type="search" className="mb-3" placeholder="Jump To" onChange={targetValue(setInputJumpTo)}/>

      <SwitchOn value={documentation.type} cases={{
        'documentation': () => use(documentation as Docs.Documentation, (documentation) =>
          <>
            {documentation.items.filter(filter).map((item) =>
              <MenuItem item={item}
                        key={getUniqueElementId(item)}
                        searchText={inputJumpTo}
                        selectedItemTree={selectedPageTree}
                        setSelectedItem={handleItemSelection}
                        level={1}
              />,
            )}
          </>,
        ),
        'router': () => use(documentation as Docs.Router, (documentation) =>
          <MenuItem item={documentation}
                    searchText={inputJumpTo}
                    key={getUniqueElementId(documentation)}
                    selectedItemTree={selectedPageTree}
                    setSelectedItem={handleItemSelection}
                    level={1}
          />,
        ),
      }}/>
    </div>
  );

  function getPageTree(
    page: string,
    documentation: Docs.Documentation | Docs.DocumentationItem | Docs.Endpoint | Docs.WebhookEndpoint,
    tree: Array<Docs.Documentation | Docs.DocumentationItem | SelectableItem>,
  ): SelectableItemTree | null {
    switch (documentation?.type) {
      case 'section-webhooks':
      case 'documentation':
      case 'router':
      case 'section':
        return (documentation.items as Array<Docs.WebhookEndpoint | Docs.Section | Docs.Documentation | Docs.Router>).reduce<SelectableItemTree | null>((
          result,
          item,
        ) => {
          return result ?? getPageTree(page, item, [...tree, documentation]);
        }, null);
      case 'page':
      case 'webhook-endpoint':
      case 'endpoint':
        return page === pageIds.get(documentation) ? [...tree, documentation] : null;
      default:
        throw new Error('whot?');
    }
  }
}

function MenuItem(props: {
  item: Docs.DocumentationItem | Docs.Endpoint | Docs.WebhookEndpoint,
  searchText: string;
  selectedItemTree: SelectableItemTree;
  setSelectedItem: (item: SelectableItem) => void;
  level: number;
}) {
  const [expandedValue, setExpanded] = useState(!ENABLE_MENU_COLLAPSE);
  const filter = filterItems(props.searchText);

  const documentation = props.item;
  const expandable = props.searchText === '' && ENABLE_MENU_COLLAPSE;
  const expanded = expandedValue || !expandable;

  const itemSelectHandler = (item: SelectableItem) => {
    return () => props.setSelectedItem(item);
  };

  useEffect(() => {
    setExpanded(props.selectedItemTree.includes(documentation));
  }, [props.item, props.selectedItemTree, documentation]);

  const expandableHeader = (title: string) => {
    return (
      <div className={classNames({
        clickable: expandable,
      }, 'd-flex align-items-center justify-content-between px-3 group-header')}
           onClick={() => setExpanded((value) => !value)}
      >
        <h5 className={'mt-3'}>
          {title}
        </h5>
        {expandable && <Chevron expanded={expanded}/>}
      </div>
    );
  };

  return (
    <MenuItemContainer>
      <Nav className="flex-column" as="ul" variant="pills">
        <SwitchOn value={documentation.type} cases={{
          'router': () => use((documentation as Docs.Router), (documentation) =>
            <Use value={documentation.items.filter(filter)} fn={(children) =>
              <WhenTruthy value={children.length > 0} then={() =>
                <Nav.Item as={'li'} className={classNames({
                  collapsed: !expanded,
                }, `level-${props.level}`)}>
                  {/* Display Router Title only when it contains direct endpoint children */}
                  {/*<WhenTruthy value={children.filter((item) => item.type === 'endpoint').length > 0 || true} then={() =>*/}
                  {/*  expandableHeader(documentation.documentation?.title ?? '', props.level)*/}
                  {/*}/>*/}

                  {expandableHeader(documentation.documentation?.title ?? '')}

                  <section className={'mb-3'} data-group="router">
                    {children.map((item) =>
                      <MenuItem item={item}
                                searchText={props.searchText}
                                key={getUniqueElementId(item)}
                                selectedItemTree={props.selectedItemTree}
                                setSelectedItem={props.setSelectedItem}
                                level={props.level + 1}
                      />,
                    )}
                  </section>
                </Nav.Item>
              }/>
            }/>,
          ),
          'section-webhooks': 'section',
          'section': () => use(documentation as Docs.Section, (documentation) =>
            <Use value={documentation.items.filter(filter)} fn={(items) =>
              <WhenTruthy value={items.length > 0} then={() =>
                <Nav.Item as={'li'} className={`level-${props.level}`}>
                  {expandableHeader(documentation.title)}

                  <section className={classNames({
                    collapsed: !expanded,
                    'mb-3': expanded,
                  })} data-group="section">
                    {items.map((item) =>
                      <MenuItem item={item} searchText={props.searchText}
                                key={getUniqueElementId(item)}
                                selectedItemTree={props.selectedItemTree}
                                setSelectedItem={props.setSelectedItem}
                                level={props.level + 1}
                      />,
                    )}
                  </section>
                </Nav.Item>
              }/>
            }/>,
          ),
          'page': () => use(documentation as Docs.Page, (item) =>
            <Nav.Item as="li" className="selectable">
              <Nav.Link
                onClick={itemSelectHandler(item)}
                className={classNames({
                  active: props.selectedItemTree.includes(item as any),
                }, 'd-flex align-items-center menu-item-page')}
              >
                {item.title}
              </Nav.Link>
            </Nav.Item>,
          ),
          'webhook-endpoint': 'endpoint',
          'endpoint': () => use(documentation as Docs.Endpoint | Docs.WebhookEndpoint, (item) =>
            <WhenDefined value={item.documentation} then={() =>
              <Nav.Item as="li" className="selectable">
                <Nav.Link
                  onClick={itemSelectHandler(item)}
                  className={classNames({
                    active: props.selectedItemTree.includes(item as any),
                  }, 'd-flex align-items-center menu-item-page')}
                >
                  {/*<Badge pill={true} className={classNames({*/}
                  {/*  'bg-success': item.method === 'GET',*/}
                  {/*  'bg-danger': item.method === 'DELETE',*/}
                  {/*  'bg-warning': item.method === 'PATCH',*/}
                  {/*  'bg-primary': item.method === 'POST',*/}
                  {/*}, 'me-2 p-1')}> </Badge>*/}
                  {item.documentation.title}
                </Nav.Link>
              </Nav.Item>
            }/>,
          ),
        }}/>
      </Nav>
    </MenuItemContainer>
  );
}

const MenuItemContainer = styled.section`
  .selectable {
    transition: all 0.2s ease-in-out;
  }

  .collapsed > section, .collapsed > section > ul > .selectable {
    height: 0;
    overflow: hidden;
    margin-bottom: 0 !important;
  }

  .group-header {
    color: #000;
    
    &>h5 {
      font-size: 14px;
      text-transform: unset;
      line-height: 21px;
      font-weight: 600;
    }
  }

  .nav-item .menu-item-page {
    color: #54595E;
    font-size: 14px;

    &.active {
      font-weight: 800;
    }
  }

  .level-1 > h5 {
    //color: red;
  }

  .level-2 {
    padding-left: 10px;
  }

  .level-3 {
    padding-left: 10px;
  }

  .level-4 {
    padding-left: 10px;
  }

  .level-2 > h5 {
    //font-size: 70%;
    //color: red;
    color: #555;
  }


  .nav-link {
    //color: #777;

    &:hover {
      color: rgb(105, 188, 216);
    }

    &.active {
      color: #fff;
    }
  }
`;

function makeIdMap(documentation: Docs.Root): Map<SelectableItem, string> {
  const result = new Map<SelectableItem, string>();

  (function traverse(item: Docs.AnyItem, path: string[]) {
    const next = (id?: string) => (item: Docs.AnyItem) => traverse(item, id ? [...path, id] : path);

    switch (item.type) {
      case 'documentation':
        return item.items.forEach(next());
      case 'router':
        return item.items.forEach(next(item.documentation?.title ?? ''));
      case 'section':
      case 'section-webhooks':
        return item.items.forEach(next(item.title));
      case 'page':
        return result.set(item, idify([...path, item.title]));
      case 'endpoint':
      case 'webhook-endpoint':
        return result.set(item, idify([...path, item.documentation.title]));
    }
  })(documentation, []);

  return result;
}


function filterItems(text: string) {
  const regex = new RegExp(text, 'i');

  return (item: Docs.AnyItem) => {
    // if (item === selectedPage) {
    //   return true;
    // }

    const result = (function getSearchableFields() {
      switch (item.type) {
        case 'section':
        case 'documentation':
        case 'router':
        case 'section-webhooks':
          return true;
        case 'webhook-endpoint':
        case 'endpoint':
          return [item.documentation.title, item.documentation.description].join('\n');
        case 'page':
          return item.title;
        default:
          console.warn('filter not applied for', item);
          return '';
      }
    })();

    if (result === true) {
      return true;
    }

    return regex.test(result);
  };
};


function idify(text: string | string[]): string {
  if (Array.isArray(text)) {
    return idify(text.join('.'));
  }

  return text
    .toLowerCase()
    .replace(/ /g, '-');
};
