export const splytWebsiteMenuItems = [
  {
    title: 'Solutions',
    url: '',
    children: [
      {
        title: 'For superapps',
        url: 'https://splyt.com/solutions/superapps/',
      },
      {
        title: 'For mobility providers',
        url: 'https://splyt.com/solutions/mobility-providers/',
      },
    ],
  },
  {
    title: 'Products',
    url: 'https://splyt.com/products/',
  },
  {
    title: 'About',
    url: '',
    children: [
      {
        title: 'Overview',
        url: 'https://splyt.com/about/',
      },
      {
        title: 'Team',
        url: 'https://splyt.com/about/#team',
      },
      {
        title: 'Press kit',
        url: 'https://splyt.com/about/#press',
      }
    ]
  },
  {
    title: 'News',
    url: 'https://splyt.com/news/',
  },
  {
    title: 'Contact us',
    url: 'https://splyt.com/solutions/superapps/#contact-us',
  }
];
