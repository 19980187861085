import { HTMLAttributes } from 'react';
import { FaCircle } from 'react-icons/fa';
import { statusCodeText } from '../lib/helpers';
import { classNames } from '../lib/utils';


export function StatusCode({
  statusCode,
  hideStatusText,
  statusText,
  ...attributes
}: {
  statusCode: number;
  statusText?: string;
  hideStatusText?: boolean;
} & HTMLAttributes<HTMLDivElement>) {

  return (
    <div className="d-flex align-items-center" {...attributes}>
      <FaCircle size="14" className={classNames({
        'text-success': statusCode < 400,
        'text-warning': statusCode < 500 && statusCode >= 400,
        'text-danger': statusCode >= 500,
      }, `me-2`)}/>
      <strong>{statusCode}</strong>
      {!hideStatusText && <span className='ms-1'>{statusText ?? statusCodeText(statusCode)}</span>}
    </div>
  );
}
