import cheerio from 'cheerio';
import { isTag } from 'cheerio/lib/utils';
import { ElementType } from 'domelementtype';
import { Element } from 'domhandler';
import React, { ReactElement, ReactText } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUniqueElementId } from '../lib/helpers';
import { CodePreview } from './CodePreview';

export function HTMLInject({
  html,
  variables,
}: {
  html: string;
  variables?: Record<string, string>;
}) {
  const navigate = useNavigate();

  const $ = cheerio.load(
    html.replace(/{{ *([^ }]+) *}}/g, (_, name) => {
      return variables?.[name] ?? _;
    }),
  );

  $('a').attr('target', '_blank');
  $('table').addClass('table');
  $('p>img').each((_, item) => {
    if (item.parent && isTag(item.parent)) {
      $(item.parent).addClass('image-container');
    }
  });

  function traverse(node: Element): ReactElement | ReactText | undefined {
    const children = node.children
      ?.map((child) => traverse(child as Element))
      .filter((item): item is ReactElement => !!item);

    const attrs = Object.fromEntries([
      ['key', getUniqueElementId(node)],
      ...Object.entries(node.attribs ?? {}).map(([k, v]) => {
        if (k === 'class') {
          return ['className', v];
        }

        return [k, v];
      }),
    ]);

    switch (node.type) {
      case ElementType.Tag:
        switch (node.tagName) {
          case 'pre': {
            const firstChild = node.children[0] as Element | undefined;

            if (firstChild?.name === 'code') {
              const lang = $(firstChild).attr('class')?.match(/language-([a-z_]+)/i)?.[1];
              const html = ($(node).find('code').html() ?? '')
                .replace(/&gt;/g, '>')
                .replace(/&lt;/g, '<');

              return (
                <div data-language={lang} className={'mb-3 rounded overflow-hidden'} key={getUniqueElementId(node)}>
                  <CodePreview text={html} lang={lang}/>
                </div>
              );
            }

            return <pre key={getUniqueElementId(node)}>{children}</pre>;
          }
          case 'a':
            return <a key={getUniqueElementId(node)} target={'_blank'} {...attrs} onClick={(event) => {
              try {
                const href = $(node).attr('href') ?? '';

                if (!/^http(s):\/\//.test(href)) {
                  navigate(`/${window.location.href.split('/').slice(3, 5).join('/')}/${href}`);
                  event.preventDefault();
                }
              } catch (e) {

              }
            }}>{children}</a>;
          default:
            return React.createElement(node.tagName, attrs, children.length ? children : undefined);
        }
      case ElementType.Text: {
        const data: string = (node as any).data;

        // return data;
        return data?.trim() ? data : undefined;
      }
    }

    return <></>;
  }

  const children = $('body>*').toArray().map((element) => {
    return traverse(element);
  });

  return (<>{children}</>);
}
