import { Docs } from '@splytech-io/router';
import { use } from '../lib/fx';
import { getUniqueElementId } from '../lib/helpers';
import { Callout } from './Callout';
import { SwitchOn, WhenDefined } from './FX';
import { HTMLInject } from './HTMLInject';
import { Markdown } from './Markdown';
import { StyledText } from './StyledText';

export function PageSections({
  items,
  variables,
}: {
  items: Docs.PageSection[];
  variables?: Record<string, string>;
}) {
  return (
    <StyledText>
      {items.map((section) =>
        <div key={getUniqueElementId(section)} className={'mb-2'}>
          <SwitchOn key={getUniqueElementId(section)} value={section.type} cases={{
            'markdown': () => use(section as Docs.PageSectionMarkdown, (section) =>
              <Markdown content={section.contents} variables={variables}/>,
            ),
            'html': () => use(section as Docs.PageSectionMarkdown, (section) =>
              <HTMLInject html={section.contents} variables={variables}/>,
            ),
            'callout': () => use(section as Docs.PageSectionCallout, (section) =>
              <Callout variant={section.severity} caption={section.title}>
                <Markdown content={section.text} variables={variables}/>
              </Callout>,
            ),
            'table': () => use(section as Docs.PageSectionTable, (section) =>
              <table className={'table table-sm'}>
                <WhenDefined value={section.headers} then={(headers) =>
                  <thead>
                  <tr>
                    {headers.map((header, index) =>
                      <th key={index}>{header}</th>,
                    )}
                  </tr>
                  </thead>
                }/>
                <tbody>
                {section.rows.map((row, index) =>
                  <tr key={index}>
                    {row.map((line, index) =>
                      <td key={index}>
                        <Markdown content={line} variables={variables}/>
                      </td>,
                    )}
                  </tr>,
                )}
                </tbody>
              </table>,
            ),
          }}/>
        </div>,
      )}
    </StyledText>
  );
}
