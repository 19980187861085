export function classNames(value: Record<string, undefined | boolean | (() => boolean)>, addition?: string): string {
  const names = Object.entries(value)
    .filter(([, v]) => typeof v === 'function' ? v() : v)
    .map(([k]) => k);

  if (addition) {
    names.push(addition);
  }

  return names.join(' ');
}

